<template>
  <div class="container px-0 h-100">
    <SettingsProductView />
  </div>
</template>

<script>
import SettingsProductView from "../../../content/settings/product/View";

export default {
  name: "SettingsProductPageView",
  components: { SettingsProductView }
};
</script>
