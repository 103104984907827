var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h3',{staticClass:"text-secondary content-heading"},[_vm._v("Ustawienia")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-6"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('h5',{staticClass:"card-title"},[_vm._v("Product")]),_c('p',{staticClass:"card-text"},[_vm._v("allows you to manage your products")]),_c('router-link',{attrs:{"to":"/settings/product"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('a',{staticClass:"btn btn-primary",attrs:{"href":href},on:{"click":navigate}},[_c('span',[_vm._v("Go")])])]}}])})],1)])]),_c('div',{staticClass:"col-sm-6"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('h5',{staticClass:"card-title"},[_vm._v("Insurer")]),_c('p',{staticClass:"card-text"},[_vm._v("allows you to manage insurers")]),_c('router-link',{attrs:{"to":"/settings/insurer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('a',{staticClass:"btn btn-primary",attrs:{"href":href},on:{"click":navigate}},[_c('span',[_vm._v("Go")])])]}}])})],1)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }